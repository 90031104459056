.navBar {
    background-color: #00000000 ;
}

/* .navBar2 {
    margin-top: 2.5rem;
} */
.navBarBrand {
    color: #000000;
}

@media screen and (min-width: 768px) {
    .navBar {
        background-color: #240a29;
    }
}
