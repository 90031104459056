@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap");

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-md,
.navbar > .container-sm {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: flex-end;
}

.navbar > .container:hover,
.navbar > .container-fluid:hover,
.navbar > .container-lg:hover,
.navbar > .container-md:hover,
.navbar > .container-sm:hover,
.navbar > .container-xl:hover,
.navbar > .container-xxl:hover {
  background: #41218073;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.navbar {
  display: block !important;
  padding: 0 0 0 0;
}

.nav-link {
  color: #d5b789 !important;
  text-align: right;
  margin: -1rem 1rem 1rem 0;
}

.navbar-toggler {
  background-color: transparent;

  width: 3.5rem;
  padding: 0.5rem;
}

/* btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #291735 !important;
  --bs-btn-border-color: #291735 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #291735 !important;
  --bs-btn-hover-border-color: #291735 !important;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #291735 !important;
  --bs-btn-active-border-color: #291735 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #291735 !important;
  --bs-btn-disabled-border-color: #291735 !important;
} */


@media screen and (min-width: 768px) {
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-md,
  .navbar > .container-sm {
    display: inline-block;
  }

  .navbar > .container:hover,
  .navbar > .container-fluid:hover,
  .navbar > .container-lg:hover,
  .navbar > .container-md:hover,
  .navbar > .container-sm:hover,
  .navbar > .container-xl:hover,
  .navbar > .container-xxl:hover {
    background: none;
  }

  .navbar {
    padding: 0;
  }

  .nav-link {
    text-align: left;
    margin: 0;
  }
}
