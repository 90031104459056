@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap");

/* mobile viewport @320px and lower */
.headerTitle {
  width: 87%;
  padding: 0.8rem 0 0 0.5rem;
  margin: -37px 0 0 0;
}

.logoStyle {
  width: 100%;
}

.headerTitleContainer {
  text-align: center;
  background-image: url("../../../images/mobile-header-images/mobile-header-left-top-corner.png");
  background-repeat: no-repeat;
  background-size: 25%;
  border-bottom: 7px solid #412180;
}

.headerImgBack {
  background-image: url("../../../images/mobile-header-images/mobile-header-image-1.jpg");
  background-size: 100%;
  height: 165vw;
  background-position: center;
  background-repeat: no-repeat;
}

/* .gradient {
  background-image: url("../../../images/mobile-header-images/purple-gradient.png");
  background-size: cover;
} */

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerLinksCon {
  padding: 0.5rem;
}

.facebookStyle,
.instagramStyle {
  width: 46%;
  padding: 0.1rem;
}

.facebookStyle:hover,
.instagramStyle:hover {
  filter: invert(1);
}

.socailMediaCol {
  width: 7rem;
}

.contentH2 {
  color: white;
  font-size: 1.8rem;
  font-family: "Roboto";
  text-align: center;
  text-shadow: 1px 3px 10px black;
  margin: 8.5rem 0 1rem 0;
}

.headerBtn,
.headerBtnCs,
.headerEbook {
  background-color: #3b1c48;
  border: 0.1px solid #d5b789;
  border-radius: 0.3rem;
  padding: 1rem 1.2rem;
  font-weight: 500;
  color: white;
  font-size: 1.1rem;
  position: relative;
  z-index: 5;
  margin: 0 0 0.3rem 0;
}

.headerBtn {
  padding: 0.5rem;
  background-color: #240929;
  margin: 1rem 0;
}

.headerBtnCs {
  font-size: 0.5rem;
}

.tourLink {
  color: white;
  text-decoration: none;
}

.tourLink:hover {
  text-decoration: none;
  color: #d5b789;
}

.headerEbook {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  text-transform: uppercase;
  width: 80%;
  margin: 0 auto 1rem auto;
}

.headerEbook>span,
.headerBtn>span {
  font-size: 1rem;
  color: #d5b789;
  font-weight: normal;
}

.headerBtn:hover,
.headerBtn:hover,
.headerBtnCs:hover,
.headerEbook:hover {
  background-color: #291735 !important;
  border-color: #cac7cd !important;
  color: white;
}

.headerBtn:hover {
  background-color: #190d21 !important;
}

.headerBtnContainer {
  text-align: center;
}

@media screen and (min-width: 370px) {
  .contentH2 {
    margin: 9.5rem 0 0 0;
  }

  .headerBtn {
    width: 80%;
  }

  .headerTitle {
    width: 81%;
  }

  .facebookStyle,
  .instagramStyle {
    width: 44%;
  }

  .socailMediaCol {
    width: 9rem;
  }
}

@media screen and (min-width: 450px) {

  .contentH2 {
    font-size: 2.4rem;
  }

  .headerBtn {
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
  }
}


@media screen and (min-width: 500px) {
  .contentH2 {
    margin: 13.5rem 0 0 0;
  }

}

@media screen and (min-width: 565px) {
  .headerTitle {
    width: 61%;
    padding: 0 0 0 0.5rem;
  }

  .headerTitleContainer {
    background-size: 17% 77%;
  }

  .headerBtn {
    padding: 1rem;
  }

  .contentH2 {
    margin: 19.5rem 0 0 0;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 600px) {
  .contentH2 {
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin: 12.5rem 0 0 0;
  }

  .headerBtn {
    padding: 1.3rem 2.5rem;
  }

  .headerTitle {
    width: 55%;
  }

  .headerImgBack {
    height: 130vw;
  }

  .headerBtn {
    padding: 1rem 2rem;
  }

  .headerTitleContainer {
    background-size: 16% 86%;
  }

}

@media screen and (min-width: 647px) {
  .contentH2 {
    margin: 18rem 0 0 0;
  }
}

@media screen and (min-width: 768px) {
  .rowHeight {
    height: 50px;
  }

  .contentH2 {
    margin: -1.5rem 0 0 0;
    font-size: 2.5rem;
    width: 200%;
    text-align: left;
  }

  .headerBtn,
  .headerEbook {
    width: 136%;
    /* padding: 1.4rem; */
  }

  .headerBtn {
    margin: 0.5rem 0;
  }

  .headerEbook {
    margin: 1rem 0 2.5rem .3rem;
  }

  .headerLinksCon {
    padding: 0;
    margin: 0 0 0 -0.45rem;
  }

  .fireBottom,
  .leftTriangle {
    display: none;
  }

  .headerTitleContainer {
    background-image: none;
    border: none;
  }

  .headerTitle {
    width: 146%;
    margin-left: -16px;
  }

  .headContainer {
    background-image: url("../../../images/header-images/header-bacground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
  }

  .headSection1 {
    background-image: url("../../../images/header-images/purple-gradient-dktp.png");
    background-size: cover;
    position: relative;
    z-index: 5;
    padding: 0 0 0 1rem;
  }

  .HeadImgSize {
    width: 116%;
    height: 100%;
    position: relative;
    z-index: 1;
    left: -82px;
    margin: 0 0 0 -8rem;
  }

  .imgContainer {
    width: 1000px;
    background-image: url("../../../images/header-images/herman-header-image2.png");
    background-size: cover;
    background-position: center left -162px;
  }

  .facebookStyle,
  .instagramStyle {
    width: 18%;
    padding: 0 0 0 0.3rem;
    margin: 0 0 2rem 7px;
  }

  .BottomRightTri {
    background-image: url("../../../images/header-images/header-bottom-corner.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    display: block;
    z-index: 1;
    top: -289px;
    height: 17.9rem;
  }
}

@media screen and (min-width: 1022px) {

  .headerEbook {
    margin: -.5rem 0 2.5rem .33rem;
  }

  .rowHeight {
    height: 30px;
  }

  .headSection1 {
    padding: 0 2rem 0 1rem;
  }

  .imgContainer {
    background-position: center left -155px;
  }

  .headerTitle {
    width: 144%;
  }

  .headerBtn {
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
  }

  .facebookStyle,
  .instagramStyle {
    width: 15%;
  }
}

@media screen and (min-width: 1045px) {
  .imgContainer {
    background-position: center left -89px;
  }
}

@media screen and (min-width: 1200px) {
  .HeadImgSize {
    left: -120px;
  }

  .headerTitle {
    width: 150%;
    margin-left: -25px;
  }
}

@media screen and (min-width: 1300px) {
  .imgContainer {
    background-position: center left -155px;
    background-size: 110%;
  }
}